body {
  margin: 0;
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-image: url(./images/background.png);
	background-size: cover;
}
