.names {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logos {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}